<template>
  <div>
    
    

    <div class="col-12">
      <div class="card mb-4">


        <div style="text-align: right; ">
            <b-button 
               variant="outline-primary"    
              @click="toggleFullLayout"
             size="sm"
            >
            <feather-icon
            icon="Maximize2Icon"
            size="12"
          />
          
          </b-button>
        </div>


        <div class="card-header">
          <h4 class="card-title">
            <span>{{RelName}}</span>
          </h4>
          

          <b-col v-if="showFiltro ? false : true ">
            <div style="float:right">
              <div class="DivBotaoLadoLado" >
              <b-button variant="danger" @click="$router.push('../Relatorios')">
                   <span>RETORNAR</span>
               </b-button>
              </div>
               
               <div class="DivBotaoLadoLado" >
               <b-button v-b-toggle="'collapse-filtro'" variant="primary" >
                 <span>FILTROS</span>
               </b-button>
               </div>
            </div>
          </b-col>


        </div>

        <b-collapse visible  id="collapse-filtro">
        <div class="card-body">
          
          <b-row>
            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Ano:"  >
                  <multiselect
                    v-model="Ano"
                    placeholder="Selecione o Ano"
                    label="name"
                    track-by="code"
                    :options="AnoOption"
                    :multiple="true"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Mes:"  >
                  <multiselect
                    v-model="Mes"
                    placeholder="Selecione o Mes"
                    label="name"
                    track-by="code"
                    :options="MesOption"
                    :multiple="true"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Região:"  >
                  <multiselect
                    v-model="Regiao"
                    placeholder="Selecione a Região"
                    label="name"
                    track-by="code"
                    :options="RegiaoOption"
                    :multiple="true"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>
              </b-form-group>
            </b-col>
          
          </b-row>

          <b-row>
            <b-col md="12" xl="6" class="mb-1">
              <b-form-group label="Holding:"  >
                  <multiselect
                    v-model="Holding"
                    placeholder="Selecione a Holding"
                    label="name"
                    track-by="code"
                    :options="HoldingOption"
                    :multiple="true"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>
              </b-form-group>
            </b-col>
            <b-col md="12" xl="3" class="mb-1">
              <b-form-group label="Grupo TOP:"  >
                  <multiselect
                    v-model="TopGrupo"
                    placeholder="Selecione o Grupo"
                    label="name"
                    track-by="code"
                    :options="TopGrupoOption"
                    :multiple="true"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
                md="6"
                xl="2"
                class="mb-1"
              >
                <b-form-group
                  label="Cod. TOP:"
                >
                  <b-form-input
                    id="CODTIPOPER"
                    placeholder=""
                    v-model="CODTIPOPER"
                  />
                 </b-form-group>
            </b-col>

            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="CIF/FOB:"  >

                 <b-form-select
                 v-model="CifFob"
                 :options="CifFobOption"
                 >
                 </b-form-select>

              </b-form-group>
            </b-col>


          </b-row>


           <b-row>
            
         <b-col
                md="6"
                xl="12"
                class="mb-1"
              >
              <div class="DivBotaoLadoLado" >
               <b-button
              variant="danger"
              type="button"
              @click="$router.push('../Relatorios')"
            >
              <span>RETORNAR</span>
            </b-button>
              </div>
              <div class="DivBotaoLadoLado" >
            <b-button v-b-toggle="'collapse-filtro'"
              variant="primary"
              type="submit"
              @click="ConsultarRelatorio"
            >
              <span>CONSULTAR</span>
            </b-button>
              </div>
          </b-col>
         </b-row>
         
        </div>
        </b-collapse>
        
      </div>
    </div>

    <div class="col-lg-12 col-24">
      <div class="row match-height">
        <div class="col-lg-4 col-md-3 col-6">
          <div class="card">
            <div class="card-body pb-50" style="position: relative;">
              <h2 class="fw-bolder mb-1">Valor Total:
                <i class="bi bi-cash-coin" style="font-size: 4rem; color: green; float:right;"></i>
              </h2>
              <h2 class="fw-bolder mb-1">R$ {{ TextFormat(this.ValorTotal,'money') }}</h2>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-3 col-6">
          <div class="card">
            <div class="card-body pb-50" style="position: relative;">
              <h2 class="fw-bolder mb-1">Frete Total:
                <i class="bi bi-truck" style="font-size: 4rem; color: red; float:right;"></i>
              </h2>
              <h2 class="fw-bolder mb-1">R$ {{ TextFormat(this.FreteTotal,'money') }}</h2>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-3 col-6">
          <div class="card">
            <div class="card-body pb-50" style="position: relative;">
              <h2 class="fw-bolder mb-1">Total de Notas:
                <i class="bi bi-filetype-xml" style="font-size: 4rem; color: orange; float:right;"></i>
              </h2>
              <h2 class="fw-bolder mb-1">{{this.NotaTotal}}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-24">
      <div class="row match-height">

        <div class="col-lg-3 col-md-3 col-6" v-if="this.NorteCard">
          <div class="card">
            <div class="card-body pb-60" style="position: relative;">
              <h4 class="fw-bolder mb-1">Norte</h4>
               <img src="@/assets/images/MapsRegions/Norte.png" style="width:100%;">
               <br><br><br><br>
               <div style="position: absolute; bottom:0%">
                 <h5 class="fw-bolder mb-1">
                  <i class="bi bi-clipboard-data" style="font-size: 1rem; color: blue;"></i>
                  {{ TextFormat(this.NorteFP,'percent') }}
                  <br>
                  <i class="bi bi-truck" style="font-size: 1rem; color: red;"></i>
                   Kg: {{ TextFormat(this.NorteFreteKG,'money') }}
                  <br>
                  <i class="bi bi-cash-coin" style="font-size: 1rem; color: green;"></i>
                   R$: {{ TextFormat(this.NorteFT,'money') }}
                 </h5>
               </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-3 col-6" v-if="this.CentroOesteCard">
          <div class="card">
            <div class="card-body pb-60" style="position: relative;">
              <h4 class="fw-bolder mb-1">Centro-Oeste</h4>
               <img src="@/assets/images/MapsRegions/CentroOeste.png" style="width:100%;">
               <br><br><br><br>
               <div style="position: absolute; bottom:0%">

                  <h5 class="fw-bolder mb-1">
                  <i class="bi bi-clipboard-data" style="font-size: 1rem; color: blue;"></i>
                  {{ TextFormat(this.CentroOesteFP,'percent') }}
                  <br>
                  <i class="bi bi-truck" style="font-size: 1rem; color: red;"></i>
                   Kg: {{ TextFormat(this.CentroOesteFreteKG,'money') }}
                  <br>
                  <i class="bi bi-cash-coin" style="font-size: 1rem; color: green;"></i>
                   R$: {{ TextFormat(this.CentroOesteFT,'money') }}
                 </h5>

               </div>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-3 col-6" v-if="this.NordesteCard">
          <div class="card">
            <div class="card-body pb-60" style="position: relative;">
              <h4 class="fw-bolder mb-1">Nordeste</h4>
               <img src="@/assets/images/MapsRegions/Nordeste.png" style="width:100%;">
               <br><br><br><br>
               <div style="position: absolute; bottom:0%">
                    
                  <h5 class="fw-bolder mb-1">
                    <i class="bi bi-clipboard-data" style="font-size: 1rem; color: blue;"></i>
                    {{ TextFormat(this.NordesteFP,'percent') }}
                    <br>
                    <i class="bi bi-truck" style="font-size: 1rem; color: red;"></i>
                     Kg:{{ TextFormat(this.NordesteFreteKG,'money') }}
                    <br>
                    <i class="bi bi-cash-coin" style="font-size: 1rem; color: green;"></i>
                     R$:{{ TextFormat(this.NordesteFT,'money') }}
                 </h5>
               </div>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-3 col-6" v-if="this.SudesteCard">
          <div class="card">
            <div class="card-body pb-60" style="position: relative;">
             <h4 class="fw-bolder mb-1">Sudeste</h4>
               <img src="@/assets/images/MapsRegions/Sudeste.png" style="width:100;">
               <br><br><br><br>
               <div style="position: absolute; bottom:0%">
               
                <h5 class="fw-bolder mb-1">
                    <i class="bi bi-clipboard-data" style="font-size: 1rem; color: blue;"></i>
                    {{ TextFormat(this.SudesteFP,'percent') }}
                    <br>
                    <i class="bi bi-truck" style="font-size: 1rem; color: red;"></i>
                     Kg: {{ TextFormat(this.SudesteFreteKG,'money') }}
                    <br>
                    <i class="bi bi-cash-coin" style="font-size: 1rem; color: green;"></i>
                     R$: {{ TextFormat(this.SudesteFT,'money') }}
                 </h5>


               </div>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-3 col-6" v-if="this.SulCard">
          <div class="card">
            <div class="card-body pb-60" style="position: relative;" >
              <h4 class="fw-bolder mb-1">Sul</h4>
               <img src="@/assets/images/MapsRegions/Sul.png" style="width:100%;">
               <br><br><br><br>
               <div style="position: absolute; bottom:0%">
                <h5 class="fw-bolder mb-1">
                    <i class="bi bi-clipboard-data" style="font-size: 1rem; color: blue;"></i>
                    {{ TextFormat(this.SulFP,'percent') }}
                    <br>
                    <i class="bi bi-truck" style="font-size: 1rem; color: red;"></i>
                     Kg: {{ TextFormat(this.SulFreteKG,'money') }}
                    <br>
                    <i class="bi bi-cash-coin" style="font-size: 1rem; color: green;"></i>
                     R$: {{ TextFormat(this.SulFT,'money') }}
                 </h5>


               </div>
            </div>
          </div>
        </div>

      </div>
    </div>

     <div class="col-12" v-if="false" >
      <div class="card mb-4">
        <div class="card-body pb-60" >
        <h4 class="fw-bolder mb-1">Tabela com informações Gerais </h4>
        <vue-apex-charts height="350"
          :options="chartPercRegiaoOptions"
          :series="chartPercRegiao"
          v-model="visibleRows"
        />
        </div>
      </div>
    </div>
    
    <div class="col-12">
            <div class="card mb-4">
              <div class="card-body pb-60" >
                
                <div style="float:right" > 
                   <b-button
                       variant="danger"
                       type="submit"
                       @click="TotalGeralRegiaoRowBack(-1)"
                       v-if="TotalGeralRegiaoPosition > 0"
                    >
                        <span>VOLTAR</span>
                   </b-button>
                </div>
              
              <div id="TotalGeralRegiaoNv1" v-if="TotalGeralRegiaoPosition == 0">
                <h4 class="fw-bolder mb-1">Total Geral Região</h4>  
                
                 <b-table
                  striped
                  responsive
                  hover
                  small
                  sticky-header
                  :items="TabNv1"
                  :fields="TabNv1fields"
                  selectable
                  @row-selected="TabNv1onRowSelected"
                  select-mode = "single"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                >
                <template #cell(FATURADO_RS)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>

                <template #cell(FATURADO_PERC)="row">
                     {{ TextFormat(row.value,'percent') }}
                </template>

                <template #cell(FATURADO_PESO)="row">
                     {{ TextFormat(row.value,'money') }}
                </template>

                <template #cell(FRETE_RS)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>

                <template #cell(FRETE_PERC)="row">
                     {{ TextFormat(row.value,'percent') }}
                </template>

                <template #cell(FRETE_RS_KG)="row">
                     {{ TextFormat(row.value,'money') }}
                </template>

                <template #cell(VLRFRETEPAGOCLIENTE)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>

                <template #cell(FRETE_PERC_LIQ)="row">
                     {{ TextFormat(row.value,'percent') }}
                </template>
                
                <template v-slot:custom-foot>
                  <tr>
                    <td class="bg-secondary text-white">
                     Total
                    </td>

                    <td class="bg-secondary text-white" colspan="2" > 
                      {{ 'R$ ' + TextFormat(TabNv1Total('FATURADO_RS'),'money')}}
                    </td>

                    <td class="bg-secondary text-white" > 
                      {{ TextFormat(TabNv1Total('FATURADO_PESO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"> 
                      {{ 'R$ ' + TextFormat(TabNv1Total('FRETE_RS'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"> 
                      {{ TextFormat(TabNv1Total('FRETE_PERC'),'percent')}}
                    </td>

                    <td class="bg-secondary text-white" > 
                      {{ TextFormat(TabNv1Total('FRETE_RS_KG'),'money')}}
                    </td>

                     <td class="bg-secondary text-white" > 
                      {{ TabNv1Total('TOTAL_NF')}}
                    </td>

                    <td class="bg-secondary text-white"> 
                      {{ 'R$ ' + TextFormat(TabNv1Total('VLRFRETEPAGOCLIENTE'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"> 
                      {{ TextFormat(TabNv1Total('FRETE_PERC_LIQ'),'percent')}}
                    </td>
                    

                  </tr>
                </template>

                </b-table>
                
                <div class="col-2" style="float:right"> 
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('TotalGeralRegiaoNv1','TotalGeralRegiao')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>
              </div>

              <div id="TotalGeralRegiaoNv2" v-if="TotalGeralRegiaoPosition == 1">
                <h4 class="fw-bolder mb-1">
                    Total Geral Região:                    
                    <a @click="TotalGeralRegiaoRowBack(0)">
                       <u><b>{{TotalGeralRegiaoSelect}}</b></u>
                   </a>
                </h4>
                 <b-table
                  striped
                  responsive
                  small
                  hover
                  sticky-header
                  :items="TabNv2"
                  :fields="TabNv2fields"
                  selectable
                  @row-selected="TabNv2onRowSelected"
                  select-mode = "single"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                >
                <template #cell(FATURADO_RS)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>

                <template #cell(FATURADO_PERC)="row">
                     {{ TextFormat(row.value,'percent') }}
                </template>

                <template #cell(FATURADO_PESO)="row">
                     {{ TextFormat(row.value,'money') }}
                </template>

                <template #cell(FRETE_RS)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>

                <template #cell(FRETE_PERC)="row">
                     {{ TextFormat(row.value,'percent') }}
                </template>

                <template #cell(FRETE_RS_KG)="row">
                     {{ TextFormat(row.value,'money') }}
                </template>

                <template #cell(VLRFRETEPAGOCLIENTE)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>

                <template #cell(FRETE_PERC_LIQ)="row">
                     {{ TextFormat(row.value,'percent') }}
                </template>
                
                <template v-slot:custom-foot>
                  <tr>
                    <td class="bg-secondary text-white" colspan="2">
                     Total
                    </td>

                    <td class="bg-secondary text-white" colspan="2" > 
                      {{ 'R$ ' + TextFormat(TabNv2Total('FATURADO_RS'),'money')}}
                    </td>

                    <td class="bg-secondary text-white" > 
                      {{ TextFormat(TabNv2Total('FATURADO_PESO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white" > 
                      {{ 'R$ ' + TextFormat(TabNv2Total('FRETE_RS'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"> 
                      {{ TextFormat(TabNv2Total('FRETE_PERC'),'percent')}}
                    </td>

                    <td class="bg-secondary text-white" > 
                      {{ TextFormat(TabNv2Total('FRETE_RS_KG'),'money')}}
                    </td>

                     <td class="bg-secondary text-white" > 
                      {{ TabNv2Total('TOTAL_NF')}}
                    </td>

                    <td class="bg-secondary text-white" > 
                      {{ 'R$ ' + TextFormat(TabNv2Total('VLRFRETEPAGOCLIENTE'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"> 
                      {{ TextFormat(TabNv2Total('FRETE_PERC_LIQ'),'percent')}}
                    </td>

                  </tr>
                </template>

                </b-table>
                
                <div class="col-2" style="float:right"> 
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('TotalGeralRegiaoNv2','TotalGeralRegiao')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>
              </div>

              <div id="TotalGeralRegiaoNv3" v-if="TotalGeralRegiaoPosition == 2">
                <h4 class="fw-bolder mb-1">
                    Total Geral Região:
                    <a @click="TotalGeralRegiaoRowBack(1)">
                       <u><b>{{TotalGeralRegiaoSelect}}</b></u>
                    </a>
                    >>
                    <a @click="TotalGeralRegiaoRowBack(2)">
                      TOP: <u><b>{{TotalGeralTopSelect}}</b></u>
                    </a>
                </h4>
                
                 <b-table
                  striped
                  responsive
                  small
                  hover
                  sticky-header
                  :items="TabNv3"
                  :fields="TabNv3fields"
                  select-mode = "single"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                >
                <template #cell(FATURADO_RS)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>

                <template #cell(FATURADO_PERC)="row">
                     {{ TextFormat(row.value,'percent') }}
                </template>

                <template #cell(FATURADO_PESO)="row">
                     {{ TextFormat(row.value,'money') }}
                </template>

                <template #cell(FRETE_RS)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>

                <template #cell(FRETE_PERC)="row">
                     {{ TextFormat(row.value,'percent') }}
                </template>

                <template #cell(FRETE_RS_KG)="row">
                     {{ TextFormat(row.value,'money') }}
                </template>

                <template #cell(VLRFRETEPAGOCLIENTE)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>

                <template #cell(FRETE_PERC_LIQ)="row">
                     {{ TextFormat(row.value,'percent') }}
                </template>
                
                <template v-slot:custom-foot>
                  <tr>
                    <td class="bg-secondary text-white" colspan="2">
                     Total
                    </td>

                    <td class="bg-secondary text-white" colspan="2" > 
                      {{ 'R$ ' + TextFormat(TabNv3Total('FATURADO_RS'),'money')}}
                    </td>

                    <td class="bg-secondary text-white" > 
                      {{ TextFormat(TabNv3Total('FATURADO_PESO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"  > 
                      {{ 'R$ ' + TextFormat(TabNv3Total('FRETE_RS'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"> 
                      {{ TextFormat(TabNv3Total('FRETE_PERC'),'percent')}}
                    </td>

                    <td class="bg-secondary text-white" > 
                      {{ TextFormat(TabNv3Total('FRETE_RS_KG'),'money')}}
                    </td>

                     <td class="bg-secondary text-white" > 
                      {{ TabNv3Total('TOTAL_NF')}}
                    </td>

                    <td class="bg-secondary text-white" > 
                      {{ 'R$ ' + TextFormat(TabNv3Total('VLRFRETEPAGOCLIENTE'),'money') }}
                    </td>

                    <td class="bg-secondary text-white"> 
                      {{ TextFormat(TabNv3Total('FRETE_PERC_LIQ'),'percent')}}
                    </td>

                  </tr>
                </template>

                </b-table>
                
                <div class="col-2" style="float:right"> 
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('TotalGeralRegiaoNv3','TotalGeralRegiao')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>
              </div>


             </div>
            </div>
    </div>


<div class="col-12">
            <div class="card mb-4">
              <div class="card-body pb-60" >
                
                              
              <div>
                <h4 class="fw-bolder mb-1">Frete Minimo</h4>  
                
                 <b-table
                  striped
                  responsive
                  small
                  hover
                  sticky-header
                  :items="TabFretMinNv1"
                  :fields="TabFretMinNv1fields"
                  select-mode = "single"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                >
                <!-- style="min-height: auto;" -->
                <template #cell(FATURADO_RS)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>

                <template #cell(FATURADO_PESO)="row">
                     {{ TextFormat(row.value,'money') }}
                </template>

                <template #cell(FRETE_RS)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>

                <template #cell(PESO_MED_ENTRAGA)="row">
                     {{ TextFormat(row.value,'money') }}
                </template>

                <template #cell(MED_FAT_ENTREGA)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>

                <template #cell(CUST_MED_ENTREGA)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>

                <template #cell(CUSTO_KG_MEDIO)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>

                <template #cell(PERC_MEDIO_ENTREGA)="row">
                     {{ TextFormat(row.value,'percent') }}
                </template>

                <template #cell(VENDAMIN)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>

                <template #cell(DIFVENDAMIN)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>
                
                </b-table>
                
                <div class="col-2" style="float:right"> 
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('TabFretMinNv1','FreteMinimo')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>
              </div>
        </div>
        </div>
  </div>      


    <div class="col-lg-12 col-24">
      <div class="row match-height">

          <div class="col-2">
            <div class="card mb-4">
              <div class="card-body pb-60" >
              <h4 class="fw-bolder mb-1">Volume Total</h4>
              <vue-apex-charts height="350"
                :options="chartVolumeAnoOptions"
                :series="chartVolumeAno"
              />
              </div>
            </div>
          </div>

          <div class="col-10">
            <div class="card mb-4">
              <div class="card-body pb-60" >
              <h4 class="fw-bolder mb-1">Volume por Região</h4>
              <vue-apex-charts height="350"
                :options="chartVolumeRegiaoOptions"
                :series="chartVolumeRegiao"
              />
              </div>
            </div>
          </div>

      </div>
    </div>

    <div class="col-lg-12 col-24">
      <div class="row match-height">

          <div class="col-12">
            <div class="card mb-4">
              <div class="card-body pb-60" >
              <h4 class="fw-bolder mb-1">Custos Logísticos</h4>
              <vue-apex-charts height="350"
                :options="chartCustoLogisticoOptions"
                :series="chartCustoLogistico"
              />
              </div>
            </div>
          </div>

      </div>
    </div>

    <div class="col-lg-12 col-24">
      <div class="row match-height">

          <div class="col-12">
            <div class="card mb-4">
              <div class="card-body pb-60" >
              <h4 class="fw-bolder mb-1">Faturado por Grupo TOP</h4>
              <vue-apex-charts height="350"
                :options="chartFatGrupoTopOptions"
                :series="chartFatGrupoTop"
              />
              </div>
            </div>
          </div>

      </div>
    </div>



  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import VueApexCharts from 'vue-apexcharts'
import BCardCode from '@core/components/b-card-code'
import 'bootstrap-icons/font/bootstrap-icons.css'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import Spinner from '@/components/Spinner.vue'
import UtilVUE from '@/customPortal/UtilVUE.vue'

import {
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BTable,
  BFormSelect,
  BFormInput,
  BCollapse,
  BCard,
  VBToggle,
} from 'bootstrap-vue'

export default {
  components: {
    VueApexCharts,
    Multiselect,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormSelect,
    BFormInput,
    BCollapse,
    BCard,
  },
  directives: { 'b-toggle': VBToggle },
  data() {
    return {
      showFiltro: true,
      FiltroRelatorio: [],
      RelName: '',
      RelRota: '',
      BCardCode,
      chartPercRegiao: [0, 0, 0, 0],
      chartPercRegiaoOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: ['Norte', 'Centro-Oeste', 'Nordeste', 'Sudeste', 'Sul'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        }],
      },
      chartVolumeRegiao: [{
        data: [],
      }],
      chartVolumeRegiaoOptions: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 40,
          style: {
            colors: ['#333'],
          },
          formatter: function (value) {
              return UtilVUE.TextFormat(value,'money')
          },
        },
        xaxis: {
          categories: ['Norte', 'Centro-Oeste', 'Nordeste', 'Sudeste', 'Sul'],
          labels: {
            show: true,
          },
        },
      },
      chartVolumeAno: [{
        data: [],
      }],
      chartVolumeAnoOptions: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            colors: ['#333'],
          },
          formatter: function (value) {
              return UtilVUE.TextFormat(value,'money')
          },
        },
        xaxis: {
          categories: ['Total'],
          position: 'top',
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (value) {
              return UtilVUE.TextFormat(value,'money')
            }
          },
        },
      },
      chartCustoLogistico: [{
        data: [],
      }],
      chartCustoLogisticoOptions: {
        chart: {
          type: 'bar'
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
            dataLabels: {
              position: 'top',
            },
          },
        },
        legend: {
  showForSingleSeries: true
},  
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#333'],
          },
          formatter: function (value) {
              return UtilVUE.TextFormat(value,'money')
          },
        },
        xaxis: {
          categories: [],
          labels: {
            show: true
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return UtilVUE.TextFormat(value,'money')
            }
          },
        },
      },
      chartFatGrupoTop: [],
      chartFatGrupoTopOptions: {
        chart: {
          type: 'bar'
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
            dataLabels: {
              position: 'top',
            },
          },
        },
        legend: {
  showForSingleSeries: true
},  
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#333'],
          },
          formatter: function (value) {
              return UtilVUE.TextFormat(value,'money')
          },
        },
        xaxis: {
          categories: [],
          labels: {
            show: true
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return UtilVUE.TextFormat(value,'money')
            }
          },
        },
      },
      TabNv1: [
        { REGIAO: '' },
        { FATURADO_RS: '' },
        { FATURADO_PERC: '' },
        { FATURADO_PESO: '' },
        { FRETE_RS: '' },
        { FRETE_PERC: '' },
        { FRETE_RS_KG: '' },
        { TOTAL_NF: '' },
        { VLRFRETEPAGOCLIENTE: '' },
        { FRETE_PERC_LIQ: '' },
      ],
      TabNv1fields: [
        { key: 'REGIAO', label: 'REGIÃO', sortable: true },
        { key: 'FATURADO_RS', label: 'R$ FATURADO', sortable: true, tdClass: 'td100' },
        { key: 'FATURADO_PERC', label: '% FATURADO', sortable: true, tdClass: 'td100' },
        { key: 'FATURADO_PESO', label: 'PESO (LIQUIDO)', sortable: true, tdClass: 'td100' },
        { key: 'FRETE_RS', label: 'FRETE', sortable: true, tdClass: 'td100' },
        { key: 'FRETE_PERC', label: '% FRETE NA REGIÃO', sortable: true, tdClass: 'td100' },
        { key: 'FRETE_RS_KG', label: 'FRETE KG', sortable: true, tdClass: 'td100' },
        { key: 'TOTAL_NF', label: 'TOTAL NF', sortable: true, tdClass: 'td100' },
        { key: 'VLRFRETEPAGOCLIENTE', label: 'R$ FRETE PAGO CLIENTE', sortable: false, tdClass: 'td100' },
        { key: 'FRETE_PERC_LIQ', label: 'RETE % LIQUIDO', sortable: false, tdClass: 'td100' },
      ],
      TabNv2: [
        { REGIAO: '' },
        { CODTIPOPER: ''},
        { DESCROPER: ''},
        { FATURADO_RS: '' },
        { FATURADO_PERC: '' },
        { FATURADO_PESO: '' },
        { FRETE_RS: '' },
        { FRETE_PERC: '' },
        { FRETE_RS_KG: '' },
        { TOTAL_NF: '' },
        { VLRFRETEPAGOCLIENTE: '' },
        { FRETE_PERC_LIQ: '' },
      ],
      TabNv2fields: [
        { key: 'REGIAO', label: 'REGIÃO', sortable: true }.hide,
        { key: 'CODTIPOPER', label: 'TOP', sortable: true },
        { key: 'DESCROPER', label: 'DESCRIÇÃO TOP', sortable: true, tdClass: 'td200' },
        { key: 'FATURADO_RS', label: 'R$ FATURADO', sortable: true, tdClass: 'td100' },
        { key: 'FATURADO_PERC', label: '% FATURADO', sortable: true, tdClass: 'td100' },
        { key: 'FATURADO_PESO', label: 'PESO (LIQUIDO)', sortable: true, tdClass: 'td100' },
        { key: 'FRETE_RS', label: 'FRETE', sortable: true, tdClass: 'td100' },
        { key: 'FRETE_PERC', label: '% FRETE NA REGIÃO', sortable: true, tdClass: 'td100' },
        { key: 'FRETE_RS_KG', label: 'FRETE KG', sortable: true, tdClass: 'td100' },
        { key: 'TOTAL_NF', label: 'TOTAL NF', sortable: true, tdClass: 'td100' },
        { key: 'VLRFRETEPAGOCLIENTE', label: 'R$ FRETE PAGO CLIENTE', sortable: false, tdClass: 'td100' },
        { key: 'FRETE_PERC_LIQ', label: 'RETE % LIQUIDO', sortable: false, tdClass: 'td100' },
      ],
      TabNv3: [
        { REGIAO: '' },
        { CODPARCMATRIZ: ''},
        { NOMEPARCMATRIZ: ''},
        { FATURADO_RS: '' },
        { FATURADO_PERC: '' },
        { FATURADO_PESO: '' },
        { FRETE_RS: '' },
        { FRETE_PERC: '' },
        { FRETE_RS_KG: '' },
        { TOTAL_NF: '' },
        { VLRFRETEPAGOCLIENTE: '' },
        { FRETE_PERC_LIQ: '' },
      ],
      TabNv3fields: [
        { key: 'REGIAO', label: 'REGIÃO', sortable: true }.hide,
        { key: 'CODPARCMATRIZ', label: 'COD.HOLDING', sortable: true, tdClass: 'td100' },
        { key: 'NOMEPARCMATRIZ', label: 'HOLDING', sortable: true, tdClass: 'td100' },
        { key: 'FATURADO_RS', label: 'R$ FATURADO', sortable: true, tdClass: 'td100' },
        { key: 'FATURADO_PERC', label: '% FATURADO', sortable: true, tdClass: 'td100' },
        { key: 'FATURADO_PESO', label: 'PESO (LIQUIDO)', sortable: true, tdClass: 'td100' },
        { key: 'FRETE_RS', label: 'FRETE', sortable: true, tdClass: 'td100' },
        { key: 'FRETE_PERC', label: '% FRETE NA REGIÃO', sortable: true, tdClass: 'td100' },
        { key: 'FRETE_RS_KG', label: 'FRETE KG', sortable: true, tdClass: 'td100' },
        { key: 'TOTAL_NF', label: 'TOTAL NF', sortable: true, tdClass: 'td100' },
        { key: 'VLRFRETEPAGOCLIENTE', label: 'R$ FRETE PAGO CLIENTE', sortable: false, tdClass: 'td100' },
        { key: 'FRETE_PERC_LIQ', label: 'RETE % LIQUIDO', sortable: false, tdClass: 'td100' },
      ],
      TabFretMinNv1: [
        { COD_CLIENTE: '' },
        { CLIENTE: '' },
        { CIDADE: '' },
        { UF: '' },
        { NOTA_FISCAL: '' },
        { VENDAMIN: '' },
        { FATURADO_RS: '' },
        { FATURADO_PESO: '' },
        { FRETE_RS: '' },
        { QTD_ENTREGA: '' },
        { PESO_MED_ENTRAGA: '' },
        { MED_FAT_ENTREGA: '' },
        { CUST_MED_ENTREGA: '' },
        { CUSTO_KG_MEDIO: '' },
        { PERC_MEDIO_ENTREGA: '' },
        { DIFVENDAMIN: '' },
      ],
      TabFretMinNv1fields: [
        { key: 'COD_CLIENTE', label: 'COD.CLIENTE', sortable: true },
        { key: 'CLIENTE', label: 'CLIENTE', sortable: true, tdClass: 'td300' },
        { key: 'CIDADE', label: 'CIDADE', sortable: true },
        { key: 'UF', label: 'UF', sortable: true },
        { key: 'NOTA_FISCAL', label: 'Nota Fiscal', sortable: false },
        { key: 'FATURADO_RS', label: 'R$ FATURADO', sortable: true, tdClass: 'td150' },
        { key: 'FATURADO_PESO', label: 'PESO (LIQUIDO)', sortable: true, tdClass: 'td150' },
        { key: 'FRETE_RS', label: 'CUSTO FRETE', sortable: true, tdClass: 'td150' },
        { key: 'QTD_ENTREGA', label: 'QTD ENTREGAS', sortable: true, tdClass: 'td100' },
        { key: 'PESO_MED_ENTRAGA', label: 'PESO MÉDIO POR ENTREGA', sortable: true, tdClass: 'td150' },
        { key: 'MED_FAT_ENTREGA', label: 'MÉDIA FAT. POR ENTREGA', sortable: true, tdClass: 'td150' },
        { key: 'VENDAMIN', label: 'MÍN. CADASTRADO SISTEMA', sortable: false, tdClass: 'td150' },
        { key: 'DIFVENDAMIN', label: 'DIF. MÉDIA FAT. X MINIMO SISTEMA', sortable: false, tdClass: 'td150' },
        { key: 'CUST_MED_ENTREGA', label: 'CUSTO MÉDIO POR ENTREGA', sortable: false, tdClass: 'td150' },
        { key: 'CUSTO_KG_MEDIO', label: 'CUSTO KG MÉDIO', sortable: false, tdClass: 'td150' },
        { key: 'PERC_MEDIO_ENTREGA', label: '% MÉDIO POR ENTREGA', sortable: false, tdClass: 'td150' },
      ],
      Mes: [],
      Ano: [],
      Regiao: [],
      Holding: [],
      TopGrupo: [],
      CifFob: '',
      AnoOption: [],
      MesOption: [],
      RegiaoOption: [
        { name: 'SUL', code: 'SUL' },
        { name: 'SUDESTE', code: 'SUDESTE' },
        { name: 'CENTRO OESTE', code: 'CENTRO OESTE' },
        { name: 'NORDESTE', code: 'NORDESTE' },
        { name: 'NORTE', code: 'NORTE' },
      ],
      HoldingOption: [
      ],
      TopGrupoOption: [
      ],
      CifFobOption: [
        { value: '', text: 'Selecione' },
        { value: 'CIF', text: 'CIF' },
        { value: 'FOB', text: 'FOB' },
      ],
      CODTIPOPER: '',
      TotalGeralRegiaoSelect: '',
      TotalGeralTopSelect: '',
      ValorTotal: 0,
      PesoTotal: 0,
      FreteTotal: 0,
      NotaTotal: 0,
      
      NortePerc: 0,
      CentroOestePerc: 0,
      NordestePerc: 0,
      SudestePerc: 0,
      SulPerc: 0,

      NorteVol: 0,
      CentroOesteVol: 0,
      NordesteVol: 0,
      SudesteVol: 0,
      SulVol: 0,

      NorteFP: 0,
      CentroOesteFP: 0,
      NordesteFP: 0,
      SudesteFP: 0,
      SulFP: 0,

      NorteFT: 0,
      CentroOesteFT: 0,
      NordesteFT: 0,
      SudesteFT: 0,
      SulFT: 0,

      NorteFreteKG: 0,
      CentroOesteFreteKG: 0,
      NordesteFreteKG: 0,
      SudesteFreteKG: 0,
      SulFreteKG: 0,

      NorteCard: true,
      CentroOesteCard: true,
      NordesteCard: true,
      SudesteCard: true,
      SulCard: true,

      columns: [
        {
          label: 'Product',
          field: 'product',
        },
      ],
      data: [
        {
          product: 'Snack',
        },
      ],
      exportarParaSelected: 'Exporte',
      exportarParaOptions: [
        { value: 'Exporte', text: 'Exporte' },
        { value: 'XLSX', text: 'Excel' },
      ],
      TotalGeralRegiaoPosition: 0,
    }
  },
  methods: {
    async MostraReport() {
      let notification = ""
      Spinner.StarLoad()
      const usuario = this.$session.get('usuario')
      
      let ParametroJson = ""
      let URLpost = `/Report/MostraReport?CODUSU=${usuario.codusu}&ROTA=${this.RelRota}`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data != null) {
            this.RelName = response.data.descricaoRelatorio
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {
       Spinner.EndLoad()
      }

    },
    async ConsultarOpcoes() {
      let notification = ""
      Spinner.StarLoad()
      const usuario = this.$session.get('usuario')
      
      let ParametroJson = ""
      let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=Holding`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.HoldingOption = response.data
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {

        let ParametroJson = ""
        let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=TOPGrupoDashPainelLogistico`
        await apiPortalSky.post(URLpost, ParametroJson).then(response => {
          if (response.status === 200) {
            if (response.data.length > 0) {
              this.TopGrupoOption = response.data
            } else {
              notification = ConexaoAPI.MsgFiltroSemRetorno()
              AlertaMsg.FormataMsg(notification)
            }
          }
        }).catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
      
      }


      if(notification === '') {

        let ParametroJson = ""
        let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=Ano`
        await apiPortalSky.post(URLpost, ParametroJson).then(response => {
          if (response.status === 200) {
            if (response.data.length > 0) {
              this.AnoOption = response.data
            } else {
              notification = ConexaoAPI.MsgFiltroSemRetorno()
              AlertaMsg.FormataMsg(notification)
            }
          }
        }).catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
      
      }



      if(notification === '') {

        let ParametroJson = ""
        let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=Mes`
        await apiPortalSky.post(URLpost, ParametroJson).then(response => {
          if (response.status === 200) {
            if (response.data.length > 0) {
              this.MesOption = response.data
            } else {
              notification = ConexaoAPI.MsgFiltroSemRetorno()
              AlertaMsg.FormataMsg(notification)
            }
          }
        }).catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
      
      }







      if(notification === '') {
       Spinner.EndLoad()
      }

    },
    async ConsultarRelatorio() {
      Spinner.StarLoad()

      //Inicio Dados Base Relatorio
      this.showFiltro = false
      const usuario = this.$session.get('usuario')
      let notification = ""
      this.FiltroRelatorio = []
      //Fim Dados Base Relatorio
      
      
      //Inicio Limpa Dados Retorno
      this.TabNv1 = []

      this.ValorTotal = 0
      this.PesoTotal = 0
      this.FreteTotal = 0
      this.NotaTotal = 0

      this.NortePerc = 0
      this.CentroOestePerc = 0
      this.NordestePerc = 0
      this.SudestePerc = 0
      this.SulPerc = 0

      this.NorteVol = 0
      this.CentroOesteVol = 0
      this.NordesteVol = 0
      this.SudesteVol = 0
      this.SulVol = 0

      this.NorteFP = 0
      this.CentroOesteFP = 0
      this.NordesteFP = 0
      this.SudesteFP = 0
      this.SulFP = 0

      this.NorteFT = 0
      this.CentroOesteFT = 0
      this.NordesteFT = 0
      this.SudesteFT = 0
      this.SulFT = 0

      this.NorteFreteKG = 0
      this.CentroOesteFreteKG = 0
      this.NordesteFreteKG = 0
      this.SudesteFreteKG = 0
      this.SulFreteKG = 0
      // Fim Limpa Dados Retorno

      //Inicio Volta Todos Niveis para posição inicial
      this.TotalGeralRegiaoRowBack(0)
      //Fim Volta Todos Niveis para posição inicial
           
      //Inicio Pega parametros de Parametros
      this.Ano.forEach(fAno => {
        const FiltroAno = {
          parametro: 'Ano',
          valor: fAno.code.toString(),
        }
        this.FiltroRelatorio.push(FiltroAno)
      })

      this.Mes.forEach(fMes => {
        const FiltroMes = {
          parametro: 'Mes',
          valor: fMes.code.toString(),
        }
        this.FiltroRelatorio.push(FiltroMes)
      })

      if(this.Regiao.length > 0)
      {
      
         this.NorteCard = false
         this.CentroOesteCard = false
         this.NordesteCard = false
         this.SudesteCard = false
         this.SulCard = false


      this.Regiao.forEach(fRegiao => {
        const FiltroRegiao = {
          parametro: 'Regiao',
          valor: fRegiao.code,
        }
        this.FiltroRelatorio.push(FiltroRegiao)

        switch (fRegiao.code) {
                case 'NORTE':
                  this.NorteCard = true
                  break
                case 'CENTRO OESTE':
                  this.CentroOesteCard = true
                  break
                case 'NORDESTE':
                  this.NordesteCard = true
                  break
                case 'SUDESTE':
                  this.SudesteCard = true
                  break
                case 'SUL':
                  this.SulCard = true
                  break
                default:
        }

       })
      }
      else{
         this.NorteCard = true
         this.CentroOesteCard = true
         this.NordesteCard = true
         this.SudesteCard = true
         this.SulCard = true
      }

      this.Holding.forEach(fHolding => {
        const FiltroHolding = {
          parametro: 'Holding',
          valor: fHolding.code.toString(),
        }
        this.FiltroRelatorio.push(FiltroHolding)
      })

      this.TopGrupo.forEach(fTopGrupo => {
        const FiltroTopGrupo = {
          parametro: 'TopGrupo',
          valor: fTopGrupo.code.toString(),
        }
        this.FiltroRelatorio.push(FiltroTopGrupo)
      })

      if(this.CODTIPOPER != '')
      {
        const FiltroCODTIPOPER = {
          parametro: 'CODTIPOPER',
          valor: this.CODTIPOPER,
        }
        this.FiltroRelatorio.push(FiltroCODTIPOPER)
      }
             
      if(this.CifFob != '')
      {
        const FiltroCifFob = {
          parametro: 'CIFFOB',
          valor: this.CifFob,
        }
        this.FiltroRelatorio.push(FiltroCifFob)
      }
      //Fim Pega parametros de Parametros

      let ParametroJson = ''
      let URLpost = ''

      ParametroJson = JSON.parse(JSON.stringify(this.FiltroRelatorio))
      URLpost = `/Report/ExecutaRelPainelLogistico?CODUSU=${usuario.codusu}&Nivel=NV1`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.TabNv1 = response.data

            this.TabNv1.forEach(Dados => {
              this.ValorTotal += Dados.FATURADO_RS
              this.PesoTotal += Dados.FATURADO_PESO
              this.FreteTotal += Dados.FRETE_RS
              this.NotaTotal += Dados.TOTAL_NF

              switch (Dados.REGIAO) {
                case 'NORTE':
                  this.NortePerc = Dados.FATURADO_PERC
                  this.NorteVol = Dados.FATURADO_PESO
                  this.NorteFP = Dados.FRETE_PERC
                  this.NorteFreteKG = Dados.FRETE_RS_KG
                  this.NorteFT = Dados.FATURADO_RS
                  break
                case 'CENTRO OESTE':
                  this.CentroOestePerc = Dados.FATURADO_PERC
                  this.CentroOesteVol = Dados.FATURADO_PESO
                  this.CentroOesteFP = Dados.FRETE_PERC
                  this.CentroOesteFreteKG = Dados.FRETE_RS_KG
                  this.CentroOesteFT = Dados.FATURADO_RS
                  break
                case 'NORDESTE':
                  this.NordestePerc = Dados.FATURADO_PERC
                  this.NordesteVol = Dados.FATURADO_PESO
                  this.NordesteFP = Dados.FRETE_PERC
                  this.NordesteFreteKG = Dados.FRETE_RS_KG
                  this.NordesteFT = Dados.FATURADO_RS
                  break
                case 'SUDESTE':
                  this.SudestePerc = Dados.FATURADO_PERC
                  this.SudesteVol = Dados.FATURADO_PESO
                  this.SudesteFP = Dados.FRETE_PERC
                  this.SudesteFreteKG = Dados.FRETE_RS_KG
                  this.SudesteFT = Dados.FATURADO_RS
                  break
                case 'SUL':
                  this.SulPerc = Dados.FATURADO_PERC
                  this.SulVol = Dados.FATURADO_PESO
                  this.SulFP = Dados.FRETE_PERC
                  this.SulFreteKG = Dados.FRETE_RS_KG
                  this.SulFT = Dados.FATURADO_RS
                  break
                default:
              }
            })

            if (this.NotaTotal > 0) {
              
              this.chartPercRegiao = [this.NortePerc,
                this.CentroOestePerc,
                this.NordestePerc,
                this.SudestePerc,
                this.SulPerc]

              this.chartVolumeRegiao = [{
                name: 'Volume Total',
                data: [this.NorteVol.toFixed(2), this.CentroOesteVol.toFixed(2), this.NordesteVol.toFixed(2), this.SudesteVol.toFixed(2), this.SulVol.toFixed(2)],
              }]
              
              
              this.chartVolumeAno = [{
                name: 'Total',
                data: [this.PesoTotal.toFixed(2)],
              }]

              
            }

          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
            
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
        
      })

      
    if(notification === '') {
      // Limpa dados Dash Custo Logistico
      this.chartCustoLogistico  = [{
        data: [],
      }]
      this.chartCustoLogisticoOptions.xaxis.categories.splice(0)

      // Consulta dados Dash Custo Logistico
      ParametroJson = JSON.parse(JSON.stringify(this.FiltroRelatorio))
      URLpost = `/Report/ExecutaRelPainelLogistico?CODUSU=${usuario.codusu}&Nivel=DS_CL1`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {

            const FATURADO_RS = [] 
            const FATURADO_PESO = [] 
            const FRETE_RS = [] 
            const FRETE_RS_KG = [] 
            const FRETE_PERC = [] 
            let Ano = ""

            response.data.forEach(Dados => {

                  if( Ano !== Dados.ANO){
                    //this.chartCustoLogisticoOptions.xaxis.categories.push(Dados.DESCRICAO)
                    this.chartCustoLogisticoOptions.xaxis.categories.push(Dados.ANO)
                    Ano = Dados.ANO
                  }
                   
                   switch (Dados.Sequencia) {
                    case 1:
                        FATURADO_RS.push(Dados.VALOR)    
                      break;
                    case 2:
                        FATURADO_PESO.push(Dados.VALOR)    
                      break;
                    case 3:
                        FRETE_RS.push(Dados.VALOR)    
                      break;
                    case 4:
                        FRETE_RS_KG.push(Dados.VALOR)    
                      break;
                    case 5:
                        FRETE_PERC.push(Dados.VALOR)    
                      break;
                   
                    
                   }

            })
            

            this.chartCustoLogistico = [{ name: 'FATURADO R$',
                                         data: FATURADO_RS,
                                        },{ name: 'KG',
                                         data: FATURADO_PESO,
                                        },{ name: 'TOTAL FRETE(R$)',
                                         data: FRETE_RS,
                                        },{ name: 'FRETE KG',
                                         data: FRETE_RS_KG,
                                        },{ name: '% FRETE',
                                         data: FRETE_PERC,
                                        }]

          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
            
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
        
      })

    }


    if(notification === '') {
      
      this.chartFatGrupoTop = []
      this.chartFatGrupoTopOptions.xaxis.categories.splice(0)
      
      ParametroJson = JSON.parse(JSON.stringify(this.FiltroRelatorio))
      URLpost = `/Report/ExecutaRelPainelLogistico?CODUSU=${usuario.codusu}&Nivel=DS_TOPG1`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {

            response.data.forEach(Dados => {

                  this.chartFatGrupoTopOptions.xaxis.categories.push(Dados.TOPGRUPO)                   
                   
                  const Valores = {
                      name: Dados.TOPGRUPO,
                      data: [Dados.FATURADO_RS],
                  }

                  this.chartFatGrupoTop.push(Valores)

            })
            

          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
            
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
        
      })

    }


    if(notification === '') {
      
      this.TabFretMinNv1 = []      
      
      ParametroJson = JSON.parse(JSON.stringify(this.FiltroRelatorio))
      URLpost = `/Report/ExecutaRelPainelLogistico?CODUSU=${usuario.codusu}&Nivel=FM1`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {          
            this.TabFretMinNv1 = response.data
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
            
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
        
      })

    }



     if(notification === '') {
       Spinner.EndLoad()
     }

    },
    TabNv1Total(Coluna) {
      let Valor  = 0

      switch (Coluna) {
        case 'FATURADO_RS':
          Valor = this.TabNv1.reduce((accum, TabNv1) => { return accum + TabNv1.FATURADO_RS }, 0.0)
        break
        case 'FATURADO_PERC':
          Valor = this.TabNv1.reduce((accum, TabNv1) => { return accum + TabNv1.FATURADO_PERC }, 0.0)
        break
        case 'FATURADO_PESO':
          Valor = this.TabNv1.reduce((accum, TabNv1) => { return accum + TabNv1.FATURADO_PESO }, 0.0)
        break
        case 'FRETE_RS':
          Valor = this.TabNv1.reduce((accum, TabNv1) => { return accum + TabNv1.FRETE_RS }, 0.0)
        break
        case 'FRETE_PERC':
          Valor = (this.TabNv1.reduce((accum, TabNv1) => { return accum + TabNv1.FRETE_RS }, 0.0) / this.TabNv1.reduce((accum, TabNv1) => { return accum + TabNv1.FATURADO_RS }, 0.0))*100
        break
        case 'FRETE_RS_KG':
          Valor = (this.TabNv1.reduce((accum, TabNv1) => { return accum + TabNv1.FRETE_RS }, 0.0) / this.TabNv1.reduce((accum, TabNv1) => { return accum + TabNv1.FATURADO_PESO }, 0.0))
        break
        case 'TOTAL_NF':
          Valor = this.TabNv1.reduce((accum, TabNv1) => { return accum + TabNv1.TOTAL_NF }, 0.0)
        break
        case 'VLRFRETEPAGOCLIENTE':
          Valor = this.TabNv1.reduce((accum, TabNv1) => { return accum + TabNv1.VLRFRETEPAGOCLIENTE }, 0.0)
        break
        case 'FRETE_PERC_LIQ':
          Valor = ((this.TabNv1.reduce((accum, TabNv1) => { return accum + TabNv1.FRETE_RS }, 0.0)
                  - this.TabNv1.reduce((accum, TabNv1) => { return accum + TabNv1.VLRFRETEPAGOCLIENTE }, 0.0))
                  / this.TabNv1.reduce((accum, TabNv1) => { return accum + TabNv1.FATURADO_RS }, 0.0))*100
        break
        
       
      }
      
      if(!isNaN(Valor))
      {
        return Valor
      }
      else{
        return 0
      }
      
    },
    TabNv2Total(Coluna) {
      let Valor  = 0

      switch (Coluna) {
        case 'FATURADO_RS':
          Valor = this.TabNv2.reduce((accum, TabNv2) => { return accum + TabNv2.FATURADO_RS }, 0.0)
        break
        case 'FATURADO_PERC':
          Valor = this.TabNv2.reduce((accum, TabNv2) => { return accum + TabNv2.FATURADO_PERC }, 0.0)
        break
        case 'FATURADO_PESO':
          Valor = this.TabNv2.reduce((accum, TabNv2) => { return accum + TabNv2.FATURADO_PESO }, 0.0)
        break
        case 'FRETE_RS':
          Valor = this.TabNv2.reduce((accum, TabNv2) => { return accum + TabNv2.FRETE_RS }, 0.0)
        break
        case 'FRETE_PERC':
          Valor = (this.TabNv2.reduce((accum, TabNv2) => { return accum + TabNv2.FRETE_RS }, 0.0) / this.TabNv2.reduce((accum, TabNv2) => { return accum + TabNv2.FATURADO_RS }, 0.0))*100
        break
        case 'FRETE_RS_KG':
          Valor = (this.TabNv2.reduce((accum, TabNv2) => { return accum + TabNv2.FRETE_RS }, 0.0) / this.TabNv2.reduce((accum, TabNv2) => { return accum + TabNv2.FATURADO_PESO }, 0.0))
        break
        case 'TOTAL_NF':
          Valor = this.TabNv2.reduce((accum, TabNv2) => { return accum + TabNv2.TOTAL_NF }, 0.0)
        break
        case 'VLRFRETEPAGOCLIENTE':
          Valor = this.TabNv2.reduce((accum, TabNv2) => { return accum + TabNv2.VLRFRETEPAGOCLIENTE }, 0.0)
        break
        case 'FRETE_PERC_LIQ':
          Valor = ((this.TabNv2.reduce((accum, TabNv2) => { return accum + TabNv2.FRETE_RS }, 0.0)
                  - this.TabNv2.reduce((accum, TabNv2) => { return accum + TabNv2.VLRFRETEPAGOCLIENTE }, 0.0))
                  / this.TabNv2.reduce((accum, TabNv2) => { return accum + TabNv2.FATURADO_RS }, 0.0))*100
        break
      }
      
      if(!isNaN(Valor))
      {
        return Valor
      }
      else{
        return 0
      }
      
    },
    TabNv3Total(Coluna) {
      let Valor  = 0

      switch (Coluna) {
        case 'FATURADO_RS':
          Valor = this.TabNv3.reduce((accum, TabNv3) => { return accum + TabNv3.FATURADO_RS }, 0.0)
        break
        case 'FATURADO_PERC':
          Valor = this.TabNv3.reduce((accum, TabNv3) => { return accum + TabNv3.FATURADO_PERC }, 0.0)
        break
        case 'FATURADO_PESO':
          Valor = this.TabNv3.reduce((accum, TabNv3) => { return accum + TabNv3.FATURADO_PESO }, 0.0)
        break
        case 'FRETE_RS':
          Valor = this.TabNv3.reduce((accum, TabNv3) => { return accum + TabNv3.FRETE_RS }, 0.0)
        break
        case 'FRETE_PERC':
          Valor = (this.TabNv3.reduce((accum, TabNv3) => { return accum + TabNv3.FRETE_RS }, 0.0) / this.TabNv3.reduce((accum, TabNv3) => { return accum + TabNv3.FATURADO_RS }, 0.0))*100
        break
        case 'FRETE_RS_KG':
          Valor = (this.TabNv3.reduce((accum, TabNv3) => { return accum + TabNv3.FRETE_RS }, 0.0) / this.TabNv3.reduce((accum, TabNv3) => { return accum + TabNv3.FATURADO_PESO }, 0.0))
        break
        case 'TOTAL_NF':
          Valor = this.TabNv3.reduce((accum, TabNv3) => { return accum + TabNv3.TOTAL_NF }, 0.0)
        break
        case 'VLRFRETEPAGOCLIENTE':
          Valor = this.TabNv3.reduce((accum, TabNv3) => { return accum + TabNv3.VLRFRETEPAGOCLIENTE }, 0.0)
        break
        case 'FRETE_PERC_LIQ':
          Valor = ((this.TabNv3.reduce((accum, TabNv3) => { return accum + TabNv3.FRETE_RS }, 0.0)
                  - this.TabNv3.reduce((accum, TabNv3) => { return accum + TabNv3.VLRFRETEPAGOCLIENTE }, 0.0))
                  / this.TabNv3.reduce((accum, TabNv3) => { return accum + TabNv3.FATURADO_RS }, 0.0))*100
        break
      }
      
      if(!isNaN(Valor))
      {
        return Valor
      }
      else{
        return 0
      }
      
    },
    TotalGeralRegiaoRowBack(RowBackTo) {
       if(RowBackTo == -1){
         this.TotalGeralRegiaoPosition = this.TotalGeralRegiaoPosition-1
       }else{
        this.TotalGeralRegiaoPosition = RowBackTo
       }
    },
    TabNv1onRowSelected(item) {
      if(item.length > 0)
      {
        if(item[0].REGIAO !== "" && item[0].REGIAO != undefined)
        {
          this.TotalGeralRegiaoPosition = 1
          this.TotalGeralRegiaoSelect = item[0].REGIAO
          this.ConsultarTabNv2(item[0].REGIAO)
        }
      }
    },
    TabNv2onRowSelected(item) {
      if(item.length > 0)
      {
        if(item[0].CODTIPOPER !== "" && item[0].CODTIPOPER != undefined)
        {
          this.TotalGeralRegiaoPosition = 2
          this.TotalGeralTopSelect = `${item[0].CODTIPOPER} - ${item[0].DESCROPER}`
          this.ConsultarTabNv3(item[0].REGIAO,item[0].CODTIPOPER)
        }
      }
    },
    async ConsultarTabNv2(REGIAO) {
      Spinner.StarLoad()

      //Inicio Dados Base Relatorio
      let notification = ''
      const usuario = this.$session.get('usuario')
      //Fim Dados Base Relatorio
      
      //Inicio Limpa Dados Retorno
      this.TabNv2 = []
      // Fim Limpa Dados Retorno

      //Cria Filtro para Segundo Nivel
      const FiltroRelatorioNv = this.FiltroRelatorio.slice()
      
      //Inicio Pega parametros de Parametros
      let FiltroAdd = {
        parametro: 'REGIAO',
        valor: REGIAO,
      }
      FiltroRelatorioNv.push(FiltroAdd)
      //Fim Pega parametros de Parametros

      const ParametroJson = JSON.parse(JSON.stringify(FiltroRelatorioNv))
      const URLpost = `/Report/ExecutaRelPainelLogistico?CODUSU=${usuario.codusu}&Nivel=NV2`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.TabNv2 = response.data
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {
       Spinner.EndLoad()
      }
    },
    async ConsultarTabNv3(REGIAO,CODTIPOPER) {
      Spinner.StarLoad()

      //Inicio Dados Base Relatorio
      let notification = ''
      const usuario = this.$session.get('usuario')
      //Fim Dados Base Relatorio
      
      //Inicio Volta Todos Niveis para posição inicial
      this.TabNv3 = []
      //Fim Volta Todos Niveis para posição inicial

      //Cria Filtro para Segundo Nivel
      const FiltroRelatorioNv = this.FiltroRelatorio.slice()

      //Inicio Pega parametros de Parametros
      let FiltroAdd = {
        parametro: 'REGIAO',
        valor: REGIAO,
      }
      FiltroRelatorioNv.push(FiltroAdd)
      
      FiltroAdd = {
        parametro: 'CODTIPOPER',
        valor: CODTIPOPER.toString(),
      }
      FiltroRelatorioNv.push(FiltroAdd)
      //Fim Pega parametros de Parametros
     
      const ParametroJson = JSON.parse(JSON.stringify(FiltroRelatorioNv))
      const URLpost = `/Report/ExecutaRelPainelLogistico?CODUSU=${usuario.codusu}&Nivel=NV3`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.TabNv3 = response.data
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {
       Spinner.EndLoad()
      }
    },
    TextFormat(valor, tipo) {
      return UtilVUE.TextFormat(valor, tipo)
    },
    ExportarDoc (ElementToExport,DocName) {
      
      let DataExport = ""
      switch (ElementToExport) {
                case 'TotalGeralRegiaoNv1':
                  DataExport = this.TabNv1
                  break
                case 'TotalGeralRegiaoNv2':
                  DataExport = this.TabNv2
                  break
                case 'TotalGeralRegiaoNv3':
                  DataExport = this.TabNv3
                  break
                case 'TabFretMinNv1':
                  DataExport = this.TabFretMinNv1
                  break
      }    
      UtilVUE.ExportDoc(this.exportarParaSelected,DocName,ElementToExport,DataExport)
      this.exportarParaSelected = 'Exporte'
      return

    },
    toggleFullLayout() {
      UtilVUE.toggleFullLayout(this.$router, this.$route);
    }
  },
  computed: {
  },
  mounted() {
    if (this.$route.name != null) {
      this.RelRota = this.$route.name
      this.MostraReport()
      this.ConsultarOpcoes()
    }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
